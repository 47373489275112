<template>
    <modal-general ref="modalVerPerfil" title="Ver perfil" size="lg" cerrar>
        <b-row class="pt-1 border-bottom">
            <!-- info paciente -->
            <b-col class="mb-0 pb-0">
                <h3>Información del paciente</h3>
                <b-avatar size="60"></b-avatar>
                <div class="text-left mb-25 mt-1">
                    <span class="font-weight-bolder">Nombre: </span>
                    <span>Gian Carlos Galán Sánchez</span>
                </div>
                <div class="text-left my-25">
                    <span class="font-weight-bolder">Edad: </span>
                    <span>21</span>
                </div>
                <div class="text-left my-25">
                    <span class="font-weight-bolder">Genero: </span>
                    <span>Masculino</span>
                </div>
                <div class="text-left my-25">
                    <span class="font-weight-bolder">Identificación: </span>
                    <span>1007193316</span>
                </div>
                <div class="text-left my-25">
                    <span class="font-weight-bolder">Riesgos: </span>
                    <span>Fiebre, Tiroidismo, Hipocondria</span>
                </div>
            </b-col>
            <!-- dispositivos -->
            <b-col
                class="mb-0 pb-0 overflow-auto"
                style="height: calc(100vh - 70vh)"
            >
                <h3>Dispositivos</h3>
                <div
                    v-for="(_, index) in 10"
                    :key="index"
                    class="d-flex border round p-1 my-50"
                >
                    <img
                        src="/img/dispositivos/blood_pressure.jpg"
                        alt="Dispositivo"
                        height="40"
                        width="40"
                        class="mr-1"
                    />
                    <div>
                        <p class="m-0">Blood pressure</p>
                        <small>Tenencia desde 20-12-2021</small>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <!-- asistencias -->
            <b-col
                style="height: calc(100vh - 71vh)"
                class="overflow-auto border-right pt-1 mt-50 pr-0"
            >
                <div>
                    <h3>Asistencias</h3>
                    <div class="d-flex justify-content-around">
                        <div class="text-center border p-50 round">
                            <p
                                class="text-success font-medium-3 font-weight-bolder mb-0"
                            >
                                12
                            </p>
                            <span>Tomadas</span>
                        </div>
                        <div class="text-center border p-50 round">
                            <p
                                class="text-info font-medium-3 font-weight-bolder mb-0"
                            >
                                12
                            </p>
                            <span>Reprogramadas</span>
                        </div>
                        <div class="text-center border p-50 round">
                            <p
                                class="text-danger font-medium-3 font-weight-bolder mb-0"
                            >
                                12
                            </p>
                            <span>Canceladas</span>
                        </div>
                    </div>
                    <div>
                        <div
                            class="d-flex align-items-center border round m-1 p-1"
                            v-for="(_, index) in 10"
                            :key="index"
                        >
                            <div class="mr-75">
                                <span
                                    class="font-medium-2 font-weight-bold text-success"
                                >
                                    #
                                </span>

                                <span>1233</span>
                            </div>
                            <div class="mr-1">
                                <p class="font-weight-bold mb-0">
                                    Profesional:
                                </p>
                                <span>Oscar Acelas</span>
                            </div>
                            <div class="mr-1">
                                <p class="font-weight-bold mb-0">Entidad:</p>
                                <span>Nueva eps</span>
                            </div>
                            <div class="col text-right cursor-pointer">
                                <feather-icon
                                    icon="ExternalLinkIcon"
                                    size="20"
                                ></feather-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
            <!-- soportes -->
            <b-col
                class="overflow-auto pt-1 mt-50 pr-0"
                style="height: calc(100vh - 71vh)"
            >
                <div class="mr-1">
                    <h3>Soportes</h3>
                    <div class="d-flex justify-content-around">
                        <div class="indicadores-soporte border text-center">
                            <div class="mt-2">
                                <p
                                    class="font-weight-bold font-medium-4 text-success mb-0"
                                >
                                    290
                                </p>
                                <span class="font-small-2">Activos</span>
                            </div>
                        </div>
                        <div class="indicadores-soporte border text-center">
                            <div class="mt-2">
                                <p
                                    class="font-weight-bold font-medium-4 text-warning mb-0"
                                >
                                    290
                                </p>
                                <span class="font-small-2">Pendientes</span>
                            </div>
                        </div>
                        <div class="indicadores-soporte border text-center">
                            <div class="mt-2">
                                <p
                                    class="font-weight-bold font-medium-4 text-primary mb-0"
                                >
                                    290
                                </p>
                                <span class="font-small-2">Cerrados</span>
                            </div>
                        </div>
                    </div>
                    <div class="px-1">
                        <div
                            v-for="(_, index) in 10"
                            :key="index"
                            class="d-flex align-items-center row p-1 border round my-1"
                        >
                            <div class="col-auto">
                                <span
                                    class="font-medium-2 font-weight-bold text-success"
                                >
                                    #
                                </span>

                                <span>1233</span>
                            </div>
                            <div class="col-7">
                                <p class="font-weight-bolder mb-0">Asunto:</p>
                                <p class="tres-puntos mb-0">
                                    Pues no sé de qué hablaste pero bueno
                                </p>
                            </div>
                            <div class="col text-right cursor-pointer">
                                <feather-icon
                                    icon="ExternalLinkIcon"
                                    size="20"
                                ></feather-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </modal-general>
</template>
<script>
import { ref } from "@vue/composition-api";
export default {
    setup(props, context) {
        function toggle() {
            context.refs.modalVerPerfil.toggle();
        }

        return { toggle };
    },
};
</script>
<style lang="css" scope>
.indicadores-soporte {
    border-radius: 50%;
    width: 80px;
    height: 80px;
}
</style>
